<template>
  <v-container fill-height>
    <v-row rows="12">
      <v-col>
        <v-data-table :loading="!hydrated" :headers="headers" :items="users" :items-per-page="10" class="elevation-1">


          <template v-slot:item.enabled="{ item }">
            <v-chip :color="item.enabled?'green': 'red'" dark>
              {{ item.enabled ? 'Yes': 'No' }}
            </v-chip>
          </template>

          <template v-slot:item.email_verified="{ item }">
            <v-icon v-if="item.email_verified" small>
              checkmark
            </v-icon>
          </template>

          <template v-slot:item.userLastModifiedDate="{ item }">
            <div v-html="getDateFromStamp(item.userLastModifiedDate)" small> </div>
          </template>

          <template v-slot:item.userCreateDate="{ item }">
            <div v-html="item.userCreateDate.replace('T', ' ').split('.')[0]" small> </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

  import { listAdminUsers } from "@/graphql/queries"

  const headers = [
    {
      text: 'Enabled?',
      align: 'start',
      sortable: true,
      value: 'enabled',
    },
    {
      text: 'User Name',
      align: 'start',
      sortable: true,
      value: 'name',
    },
    { text: 'email', value: 'email' },
    { text: 'Email Verified?', value: 'email_verified' },
    { text: 'Status', value: 'userStatus' },
    { text: 'Register date', value: 'userCreateDate' },
    { text: 'Last Modified', value: 'userLastModifiedDate' },
  ]

  export default {
    name: 'OperatorConfig',
    data: () => ({
      headers,
      hydrated: false,
      users: []
    }),
    methods: {
      async hydrate() {
        let self = this;
        self.hydrated = false
        const poolId = "blah"
        const result = await self.$Amplify.API.graphql({ query: listAdminUsers, variables: { poolId }, fetchPolicy: 'network-only' })
        const users = result.data.listAdminUsers?.data
        if (!users) {
          console.error("Could not retrieve admin users")
        }
        else {
          self.users = users
        }
        self.hydrated = true
      },
      getDateFromStamp(timestamp) {
        let incomingDate = new Date(timestamp)
        let dateNow = Date.now()

        let d = Math.abs(incomingDate - dateNow) / 1000;                           // delta
        let r = {};                                                                // result
        let s = {                                                                  // structure
          year: 31536000,
          month: 2592000,
          week: 604800, // uncomment row to ignore
          day: 86400,   // feel free to add your own row
          hour: 3600,
          minute: 60,
          second: 1
        };

        let rtString = ''

        Object.keys(s).forEach(function (key) {
          r[key] = Math.floor(d / s[key]);
          d -= r[key] * s[key];
        });
        // console.info(`We have the time obj here as `, r)
        Object.keys(r).forEach((key) => {
          if (rtString == '' && r[key] > 0) {
            let keyStr = key
            keyStr = r[key] > 1 ? `${keyStr}s` : keyStr
            rtString = `${r[key]} ${keyStr} ago`
          }
        })
        return rtString
      },
    },
    mounted() {
      this.hydrate()
    }
  }
</script>

<style lang="scss" scoped>

</style>