var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"rows":"12"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":!_vm.hydrated,"headers":_vm.headers,"items":_vm.users,"items-per-page":10},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.enabled?'green': 'red',"dark":""}},[_vm._v(" "+_vm._s(item.enabled ? 'Yes': 'No')+" ")])]}},{key:"item.email_verified",fn:function(ref){
var item = ref.item;
return [(item.email_verified)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" checkmark ")]):_vm._e()]}},{key:"item.userLastModifiedDate",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"small":""},domProps:{"innerHTML":_vm._s(_vm.getDateFromStamp(item.userLastModifiedDate))}})]}},{key:"item.userCreateDate",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"small":""},domProps:{"innerHTML":_vm._s(item.userCreateDate.replace('T', ' ').split('.')[0])}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }